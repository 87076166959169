import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import YouTube from 'react-youtube';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useSnapshot } from 'valtio';
import useSWR from 'swr-0-5-6';
import { freemanStore } from '../freemanStore';
import { sortBy } from 'lodash';
/** cms youtube playlist */
export const useGetCMSVideoPlayListData = (playlistID) => {
    const apiUrl = 'https://cms.futures-op.com/api/video-playlists?populate=video_entities&filters[id][$eq]=' +
        playlistID;
    const res = useSWR(apiUrl, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    /** 將特定的播放清單取出來 */
    const playlistData = res.data?.data[0];
    const playlistName = playlistData?.attributes.title;
    const videoEntities = playlistData?.attributes.video_entities.data;
    /** 依照 updatedAt(更新時間 最新的在最頂層) */
    const videos = sortBy(videoEntities, s => s.attributes.createdAt).reverse();
    return { videos, playlistName };
};
/** get youtube video id */
const getYoutubeVideoID = (videoURL) => {
    //可能有三種網址模式要解析ID
    let videoID = '';
    //URL：https://www.youtube.com/watch?v=VIDEO_ID
    let match = videoURL.match(/[?&]v=([^&]+)/);
    if (match) {
        videoID = match[1];
    }
    else {
        //URL：https://www.youtube.com/watch?v=VIDEO_ID&list=PLAYLIST_ID
        match = videoURL.match(/(?:\/|%3D|v=)([a-zA-Z0-9_-]{11})/);
        if (match) {
            videoID = match[1];
        }
        else {
            //URL：https://youtu.be/VIDEO_ID
            match = videoURL.match(/(?:\/|%3D)([a-zA-Z0-9_-]{11})/);
            if (match) {
                videoID = match[1];
            }
        }
    }
    return videoID;
};
/** get youtube video thumbnail */
const getYoutubeVideoThumbnail = (videoURL) => {
    const videoID = getYoutubeVideoID(videoURL);
    const result = 'https://img.youtube.com/vi/' + videoID + '/hqdefault.jpg';
    return result;
};
////////////////////////////////////////////////////////////////
/** 使用頻道ID獲取影片資料 */
export const useGetYoutubeDataByChannel = (channelID) => {
    const apiUrl = 'https://cms.futures-op.com/api/youtube-videos?filters%5Bchannel_id%5D%5B$eq%5D=' + channelID;
    const res = useSWR(apiUrl, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 使用播放列表ID獲取影片資料 */
export const useGetYoutubeDataByPlaylist = (playlistID) => {
    const apiUrl = 'https://cms.futures-op.com/api/youtube-videos?filters%5Bplaylist_id%5D%5B$eq%5D=' + playlistID;
    const res = useSWR(apiUrl, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 設定小習播放器是否自動播放 */
const opts = {
    playerVars: {
        autoplay: 0,
    },
};
const ChannelButton = memo(function ChannelButton(props) {
    const state = useSnapshot(freemanStore);
    const selected = state.currentChannelName === props.channelName;
    const pageType = props.pageType;
    const channelName = props.channelName;
    const videoID = props.videoID;
    return (<span css={css `
        ${classes.column.channelName};
        border-bottom: 1.6px solid ${selected ? '#ff0000' : 'transparent'};
        font-size: ${selected ? '12.6' : '12.4'}px;
        color: ${selected ? '#f1f1f1' : '#b0b0b0'};
      `} onClick={() => {
            freemanStore.videoPageType = pageType;
            freemanStore.currentChannelName = channelName;
            if (videoID !== undefined) {
                freemanStore.currentVideoId = videoID;
            }
        }}>
      {props.children}
    </span>);
});
export const VideoContent = memo(function VideoContent() {
    const state = useSnapshot(freemanStore);
    const futuresaiVideoDatas = useGetYoutubeDataByChannel('UCxn_ZSz7lavsYxzlJKNr-LA').data;
    const freemaniVideoDatas = useGetYoutubeDataByChannel('UCKYsEcghAPJvINpq7wJszOA').data;
    const futuresVideo = futuresaiVideoDatas?.data.map(s => s.attributes);
    const freemanVideo = freemaniVideoDatas?.data.map(s => s.attributes);
    const userVideo = useGetCMSVideoPlayListData(1);
    const dataOfChannelType = () => {
        //假設有多種 作者頻道
        /** 期天 */
        if (state.currentChannelName === 'futuresai') {
            return futuresVideo?.flatMap(s => s.entry);
        }
        /** 自由人 */
        if (state.currentChannelName === 'freeman') {
            return freemanVideo?.flatMap(s => s.entry);
        }
        return futuresVideo?.flatMap(s => s.entry);
    };
    //預設的影片id
    const defaultVideoID = freemanVideo?.[0]?.entry?.[0].video_id ?? '';
    useEffect(() => {
        freemanStore.currentVideoId = defaultVideoID;
    }, [defaultVideoID]);
    return (<div css={classes.container}>
      {/* 左側控制欄 */}
      <div css={classes.column.container}>
        <img src={'./freeman/yt_logo_rgb_dark.png'} css={classes.youtubeIcon}/>
        <ChannelButton pageType='player' channelName='player' videoID={state.currentVideoId}>
          影片播放器
        </ChannelButton>
        <ChannelButton pageType='list' channelName='freeman'>
          自由人
        </ChannelButton>
        <ChannelButton pageType='customize' channelName='freeman2'>
          {userVideo.playlistName}
        </ChannelButton>
        <ChannelButton pageType='list' channelName='futuresai'>
          期天大勝
        </ChannelButton>
        {/* <ChannelButton
          pageType='player'
          videoID='LbS-xQ67fos'
          channelName='ebc'
        >
          東森財經直播
        </ChannelButton> */}
      </div>
      {/* 播放器 */}
      {state.videoPageType === 'player' && (<div css={classes.videoPlayer.container}>
          <YouTube videoId={state.currentVideoId} opts={opts}/>
        </div>)}
      {state.videoPageType === 'list' && (<div css={classes.videoList.container}>
          {dataOfChannelType()?.map(video => (<div css={classes.card.container} onClick={() => {
                    freemanStore.currentVideoId = video.video_id;
                    freemanStore.videoPageType = 'player';
                    freemanStore.currentChannelName = 'player';
                }} key={video.video_id}>
              <img css={classes.card.images} src={video.media_thumbnail_url}/>
              <span css={classes.card.titleContainer}>
                <span css={classes.card.title}> {video.title}</span>
              </span>
            </div>))}
        </div>)}
      {state.videoPageType === 'customize' && (<div css={classes.videoList.container}>
          {userVideo.videos?.map(video => {
                return (<div css={classes.card.container} onClick={() => {
                        freemanStore.currentVideoId = getYoutubeVideoID(video.attributes.video_url);
                        freemanStore.videoPageType = 'player';
                        freemanStore.currentChannelName = 'player';
                    }} key={video.attributes.video_url}>
                <img css={classes.card.images} src={getYoutubeVideoThumbnail(video.attributes.video_url)}/>
                <span css={classes.card.titleContainer}>
                  <span css={classes.card.title}>{video.attributes.title}</span>
                </span>
              </div>);
            })}
        </div>)}
    </div>);
});
const classes = {
    container: css `
    display: grid;
    grid-template-columns: 108px 1fr;
    width: 100%;
    height: 100%;
  `,
    youtubeIcon: css `
    width: 90px;
    padding: 6px 0;
    margin-bottom: 6px;
  `,
    column: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      padding: 2px;
      background-color: #212020;
      border-radius: 4px;
    `,
        channelName: css `
      ${fill_horizontal_cross_center};
      height: 24px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        border-bottom: 1.6px solid #787878;
      }
      transition: 0.3s;
    `,
    },
    videoPlayer: {
        container: css `
      width: 100%;
      height: 100%;
      & > * {
        width: 100%;
        height: 100%;
      }
      & > * > iframe {
        width: 100%;
        height: 100%;
      }
    `,
    },
    videoList: {
        container: css `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      gap: 6px;
      padding: 2px;
    `,
    },
    card: {
        container: css `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 100px 1fr;
      width: 100%;
      max-height: 72px;
      border-radius: 4px;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        background-color: #282828;
      }
    `,
        images: css `
      width: 100%;
      height: 100%;
      max-height: 72px;
    `,
        titleContainer: css `
      ${fill_vertical_cross_center};
      max-height: 60px;
    `,
        title: css `
      width: 100%;
      height: auto;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #f1f1f1;
      padding: 0 2px;
      overflow: hidden;
    `,
        description: css `
      width: 100%;
      height: 100%;
      font-size: 12px;
      line-height: 14px;
      color: #cccccc;
      white-space: pre-wrap;
      overflow: hidden;
      padding: 0 2px;
      transform: scale(0.94);
      margin-left: -6%;
    `,
        date: css `
      ${fill_vertical_cross_center};
      align-items: start;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #f1f1f1;
      & > * {
        border-radius: 6px;
        background-color: #252525;
        transform: scale(0.8);
        padding: 1px 4px;
      }
    `,
    },
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
