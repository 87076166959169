import { css, keyframes } from '@emotion/react';
import { memo, useRef, useState } from 'react';
import { fill_horizontal, fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { StockKbar, stockKbarBoxStore } from './StockKbar';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
//關於拖曳移動的想法
//getBoundingClientRect用法 https://www.shubo.io/get-bounding-client-rect/
//event.clientX用法 https://blog.csdn.net/u012557814/article/details/117123075
const StockKbarBoxView = memo(function StockKbarBoxView(props) {
    const state = useSnapshot(stockKbarBoxStore);
    const valueQuote = props.quote;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    //滑鼠左鍵按下
    const handleMouseDown = (event) => {
        const containerRefCurrent = containerRef.current;
        if (containerRefCurrent) {
            setIsDragging(true);
            setStartX(event.clientX - containerRefCurrent.getBoundingClientRect().left);
            setScrollLeft(containerRefCurrent.scrollLeft);
        }
    };
    //滑鼠左鍵按下後開始移動
    const handleMouseMove = (event) => {
        if (!isDragging)
            return;
        const containerRefCurrent = containerRef.current;
        if (containerRefCurrent) {
            const x = event.clientX - containerRefCurrent.getBoundingClientRect().left;
            const walk = x - startX;
            containerRefCurrent.scrollLeft = scrollLeft - walk;
        }
    };
    //滑鼠左鍵放開
    const handleMouseUp = () => {
        setIsDragging(false);
    };
    const handleWheel = (event) => {
        const delta = event.deltaX || event.deltaY;
        const containerRefCurrent = containerRef.current;
        if (containerRefCurrent) {
            const currentScrollLeft = containerRefCurrent.scrollLeft;
            containerRefCurrent.scrollLeft = currentScrollLeft + delta;
        }
    };
    return (<div css={css `
          ${classes.container};
        `}>
        {/* 左側: 座標區域、空白區 */}
        <div css={classes.content.axisContent}>
          <div css={classes.priceAxis}>
            <span>10%</span>
            <span>5%</span>
            <span>0%</span>
            <span>-5%</span>
            <span>-10%</span>
          </div>
          <div css={classes.none.container}>{''}</div>
        </div>
        {/* 右側: K棒圖表區域、商品名稱區域 */}
        <div css={classes.content.chartContent} ref={containerRef} onWheel={handleWheel} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
          {/* 水平軸線與框架 */}
          <div css={classes.chart.container}>
            <div css={classes.chart.horizontalContainer}>
              <div css={classes.chart.horizontalLines}/>
              <div css={classes.chart.horizontalLines}/>
              <div css={classes.chart.horizontalLines}/>
              <div css={classes.chart.horizontalLines}/>
              <div css={classes.chart.horizontalLines}/>
              <div />
            </div>
            {/* K棒圖表區域 */}
            <div css={classes.chart.kbarContainer}>
              {valueQuote.map(s => {
            return (<StockKbar key={s.symbol} quote={s}/>);
        })}
            </div>
          </div>
          {/* 商品名稱區域 */}
          <div css={classes.symbolName.container}>
            {valueQuote.map(s => {
            return (<span key={s.symbol} css={css `
                    ${classes.symbolName.nameItem};
                    width: ${state.fontSize * 1.2}px;
                    font-size: ${state.fontSize}px;
                  `}>
                  {dictionary[s.symbol]}
                </span>);
        })}
          </div>
        </div>
      </div>);
});
export default StockKbarBoxView;
const fadeIn = keyframes `
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
const classes = {
    container: css `
    width: 100%;
    height: 100%;
    min-height: 136px;
    display: grid;
    grid-template-columns: 40px 1fr;
    padding: 4px;
    font-size: 14px;
    user-select: none;
    overflow-y: display;
  `,
    content: {
        axisContent: css `
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 56px;
    `,
        chartContent: css `
      display: grid;
      grid-template-rows: 1fr 56px;
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 3px;
        width: 3px;
        border-radius: 4px;
        background-color: #555;
      }
      &::-webkit-scrollbar-thumb {
        height: 3px;
        width: 3px;
        border-radius: 4px;
        background-color: #ccc;
      }
      cursor: grab;
    `,
    },
    //座標軸
    priceAxis: css `
    width: 100%;
    display: grid;
    grid-template-rows: 8% 1fr 1fr 1fr 1fr 8%;
    font-size: 12px;
    line-height: 13px;
    & > span {
      ${fill_vertical_all_center};
      align-items: end;
      padding-right: 8%;
      transform: translateY(50%);
    }
    & > span:nth-of-type(1) {
      color: #ff0000;
    }
    & > span:nth-of-type(2) {
      color: #ff0000;
    }
    & > span:nth-of-type(4) {
      color: #00aa00;
    }
    & > span:nth-of-type(5) {
      color: #00aa00;
    }
  `,
    chart: {
        container: css `
      position: relative;
      width: 100%;
      height: 100%;
    `,
        horizontalContainer: css `
      position: absolute;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 8% 1fr 1fr 1fr 1fr 8%;
    `,
        horizontalLines: css `
      width: 100%;
      border-bottom: 1px solid #565656aa;
    `,
        kbarContainer: css `
      ${fill_horizontal};
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0 4px;
      gap: 6px;
    `,
    },
    symbolName: {
        container: css `
      ${fill_horizontal};
      padding: 0 4px;
      gap: 6px;
    `,
        nameItem: css `
      ${fill_horizontal_cross_center};
      writing-mode: vertical-lr;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      user-select: none;
      flex-shrink: 0;
      animation: ${fadeIn} 0.8s linear forwards;
      &:hover {
        overflow: visible;
      }
    `,
    },
    none: {
        container: css `
      ${fill_horizontal};
      padding: 0 8px;
      gap: 4px;
    `,
    },
};
