import { proxy } from 'valtio';
import { FuiUserAvatar } from '../heineken_template/components/FuiUserAvatar';
export const freemanStore = proxy({
    videoPageType: 'list',
    currentChannelName: 'freeman',
    currentVideoId: '',
    popularSymbols: [],
    tw50Symbols: [],
    highPriceSymbols: [],
    rowPageType: 'info',
    ampTabs: 'day',
    tradeSymbol: 'TXAM-1',
    userAvatar: new FuiUserAvatar(),
});
