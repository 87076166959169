import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
export const Sungop_Topbar = memo(function Sungop_Topbar(props) {
    const { isPc, isPad } = useMedia();
    const showRightBurger = props.rightBurger ?? false;
    return (<div css={css `
          ${fill_horizontal_cross_center};
          justify-content: end;
          background-color: #262934;
          border-bottom: 1px solid #2b2e38;
        `}>
        {isPc && (<ChartingServerSwitch charting={store.charting} css={css `
              background-color: #2c2e39;
            `}/>)}
        {isPc && <UserAvatarAsDialogButton />}

        {!isPc && (<Preset_Topbar showLeftBurger showRightBurger={showRightBurger} componentsInRight={<div css={css `
                  ${fill_horizontal_cross_center};
                `}>
                <ChartingServerSwitch charting={store.charting}/>
                <UserAvatarAsDialogButton />
              </div>}/>)}
      </div>);
});
